@mixin headingText(
  $relativeFontSize,
  $lineHeight,
  $color: var(--ion-color-tertiary)
) {
  color: $color;
  font-size: $relativeFontSize;
  line-height: $lineHeight;
}

@mixin regularText($relativeFontSize, $lineHeight) {
  font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
  font-size: $relativeFontSize;
  line-height: $lineHeight;
}
