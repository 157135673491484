.caret {
  font-size: 1rem;
  &.tertiary-color {
    color: var(--ion-color-tertiary);
  }
  &.mu-rem-lg {
    font-size: 1.5rem;
  }
  &.caret-right::after {
    content: '>';
    padding-left: var(--mu-spacing-md);
  }
  &.caret-left::before {
    content: '<';
    padding-right: var(--mu-spacing-md);
  }
  &.caret-down {
    transform: rotate(90deg);
    &::after {
      content: '>';
      transform: rotate(90deg);
    }
  }
}
