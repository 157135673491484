@import 'src/theme/media_mixins';

abbr[title] {
  position: relative;
  text-decoration: underline dotted 1px var(--ion-color-medium);
  &:hover,
  &:focus {
    &::after {
      content: attr(title);
      /* position tooltip like the native one */
      position: absolute;
      left: 0;
      bottom: -2rem;
      width: auto;
      white-space: nowrap;

      /* style tooltip */
      background-color: #1e1e1e;
      color: #fff;
      border-radius: 3px;
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
    }
  }
}
.tooltip[tooltip] {
  &:hover,
  &:focus {
    &::after {
      content: attr(tooltip);
      /* position tooltip like the native one */
      position: absolute;
      left: 0;
      bottom: -2rem;
      width: auto;
      white-space: nowrap;

      /* style tooltip */
      background-color: #1e1e1e;
      color: #fff;
      border-radius: 3px;
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
      @include tablet {
        display: none;
      }
    }
  }
}
