.b {
  &-none {
    border: none;
  }
  &-primary {
    border-color: var(--ion-color-primary);
  }
  &-secondary {
    border-color: var(--ion-color-secondary);
  }
  &-tertiary {
    border-color: var(--ion-color-tertiary);
  }
  &-danger {
    border-color: var(--ion-color-danger);
  }
}
