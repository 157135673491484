// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
  --ion-color-primary: #001c3d;
  --ion-color-primary-rgb: 0, 28, 61;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #001936;
  --ion-color-primary-tint: #1a3350;
  --ion-color-primary-gradient: linear-gradient(
    0deg,
    rgba(26, 51, 80, 1) -100%,
    rgba(0, 28, 61, 1) 100%
  );

  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #00a2db;
  --ion-color-tertiary-rgb: 0, 162, 219;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #008fc1;
  --ion-color-tertiary-tint: #1aabdf;

  --ion-color-success: #13ca17;
  --ion-color-success-rgb: 19, 202, 23;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #11b214;
  --ion-color-success-tint: #2bcf2e;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e84e10;
  --ion-color-danger-rgb: 232, 78, 16;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cc450e;
  --ion-color-danger-tint: #ea6028;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --color-pdf: #d5353b;
  --color-doc: #2b579a;
  --color-docx: #2b579a;
  --color-xls: #217346;
  --color-xlsx: #217346;
  --color-youtube: #ff0000;
  --color-facebook: #4267b2;
  --color-twitter: #1da1f2;
  --color-linkedin: #2867b2;
  --color-instagram: #833ab4;

  --ion-grid-columns: 24;

  --ion-grid-padding: 0;
  --ion-grid-padding-xs: 0;
  --ion-grid-padding-sm: 0;
  --ion-grid-padding-md: 0;
  --ion-grid-padding-lg: 0;
  --ion-grid-padding-xl: 0;

  --ion-grid-column-padding: 0;
  --ion-grid-column-padding-xs: 0;
  --ion-grid-column-padding-sm: 0;
  --ion-grid-column-padding-md: 0;
  --ion-grid-column-padding-lg: 0;
  --ion-grid-column-padding-xl: 0;

  --mu-color-input-text: #7f8d9e;
  --mu-color-border: #c3c3c3;
  --mu-color-border-dark: #e7e7e7;
  --mu-color-border-focused: var(--mu-color-input-text);
  --mu-color-ruler: #e1e1e1;
  --mu-color-background: #f4f4f4;

  --mu-spacing-xxs: 4px;
  --mu-spacing-xs: 8px;
  --mu-spacing-sm: 12px;
  --mu-spacing-md: 16px;
  --mu-spacing-lg: 24px;
  --mu-spacing-xl: 40px;
  --mu-button-text-height: 40px;
  --mu-border-width: 1px;
  --mu-border-radius: 4px;

  //surveyjs variables
  --primary: var(--ion-color-primary);
  --foreground: var(--ion-color-primary);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors => overriding with default white theme
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #001c3d;
    --ion-color-primary-rgb: 0, 28, 61;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #001936;
    --ion-color-primary-tint: #1a3350;

    --ion-color-secondary: #ffffff;
    --ion-color-secondary-rgb: 255, 255, 255;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #e0e0e0;
    --ion-color-secondary-tint: #ffffff;

    --ion-color-tertiary: #00a2db;
    --ion-color-tertiary-rgb: 0, 162, 219;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #008fc1;
    --ion-color-tertiary-tint: #1aabdf;

    // FIXME: only main color is set, how about contrast etc?
    --ion-color-success: #13ca17;
    --ion-color-success-rgb: 19, 202, 23;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #11b214;
    --ion-color-success-tint: #2bcf2e;

    // FIXME: only main color is set, how about contrast etc?
    --ion-color-danger: #e84e10;
    --ion-color-danger-rgb: 232, 78, 16;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cc450e;
    --ion-color-danger-tint: #ea6028;
  }

  /*
   * iOS Dark Theme => overriding with default white theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #001c3d;
    --ion-text-color-rgb: 0, 28, 61;

    --ion-color-step-50: #f2f4f5;
    --ion-color-step-100: #e6e8ec;
    --ion-color-step-150: #d9dde2;
    --ion-color-step-200: #ccd2d8;
    --ion-color-step-250: #bfc6cf;
    --ion-color-step-300: #b3bbc5;
    --ion-color-step-350: #a6b0bb;
    --ion-color-step-400: #99a4b1;
    --ion-color-step-450: #8c99a8;
    --ion-color-step-500: #808e9e;
    --ion-color-step-550: #738294;
    --ion-color-step-600: #66778b;
    --ion-color-step-650: #596b81;
    --ion-color-step-700: #4d6077;
    --ion-color-step-750: #40556e;
    --ion-color-step-800: #334964;
    --ion-color-step-850: #263e5a;
    --ion-color-step-900: #193350;
    --ion-color-step-950: #0d2747;

    --ion-item-background: #ffffff;
    --ion-card-background: #e1e1e1;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme  => overriding with default light theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #001c3d;
    --ion-text-color-rgb: 0, 28, 61;

    --ion-color-step-50: #f2f4f5;
    --ion-color-step-100: #e6e8ec;
    --ion-color-step-150: #d9dde2;
    --ion-color-step-200: #ccd2d8;
    --ion-color-step-250: #bfc6cf;
    --ion-color-step-300: #b3bbc5;
    --ion-color-step-350: #a6b0bb;
    --ion-color-step-400: #99a4b1;
    --ion-color-step-450: #8c99a8;
    --ion-color-step-500: #808e9e;
    --ion-color-step-550: #738294;
    --ion-color-step-600: #66778b;
    --ion-color-step-650: #596b81;
    --ion-color-step-700: #4d6077;
    --ion-color-step-750: #40556e;
    --ion-color-step-800: #334964;
    --ion-color-step-850: #263e5a;
    --ion-color-step-900: #193350;
    --ion-color-step-950: #0d2747;

    --ion-item-background: #ffffff;
    --ion-card-background: #e1e1e1;
    --ion-toolbar-background: #ffffff;
    --ion-tab-bar-background: #ffffff;
  }
}
