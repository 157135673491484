.mu-ff1 {
  font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
}
.mu-ff2 {
  font-family: 'TheSans B6 SemiBold', Arial, Helvetica, sans-serif;
}
.mu-lh1 {
  line-height: 0.875rem;
}
.mu-lh2 {
  line-height: 1rem;
}
.mu-lh3 {
  line-height: 1.25rem;
}
.mu-lh4 {
  line-height: 1.5rem;
}
.mu-lh5 {
  line-height: 2rem;
}
.mu-fs0 {
  font-size: 0.6rem;
}
.mu-fs1 {
  font-size: 0.875rem;
}
.mu-fs2 {
  font-size: 1rem;
}
.mu-fs3 {
  font-size: 1.25rem;
}
.mu-fs4 {
  font-size: 1.5rem;
}
.mu-fs5 {
  font-size: 2rem;
}

.mu-t-preline {
  white-space: pre-line;
}
