$desktop-width-until: 1023px;
$desktop-width-from: 1024px;

@mixin tablet {
  @media (max-width: #{$desktop-width-until}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width-from}) {
    @content;
  }
}
