@import './fonts_mixins';
@import './media_mixins';

@font-face {
  font-family: 'TheSans Regular';
  src: url('/assets/fonts/TheSans-Regular.ttf');
}

@font-face {
  font-family: 'TheSans B6 SemiBold';
  src: url('/assets/fonts/TheSans_B6_SemiBold.ttf');
}

h1 {
  @include headingText(2rem, 2.5rem, var(--ion-color-danger));
  font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
}

h2 {
  @include headingText(1.5rem, 2.25rem);
  @include desktop {
    @include headingText(1.75rem, 2.25rem);
  }
  font-family: 'TheSans B6 SemiBold', Arial, Helvetica, sans-serif;
}
h3 {
  @include headingText(1.5rem, 2.5rem);
  font-family: 'TheSans B6 SemiBold', Arial, Helvetica, sans-serif;
}
h4 {
  @include headingText(1.25rem, 2rem);
  font-family: 'TheSans B6 SemiBold', Arial, Helvetica, sans-serif;
}
h5 {
  @include regularText(1rem, 1rem);
  color: var(--ion-color-tertiary);
}

p {
  @include regularText(1rem, 1rem);
  &.paragraph-large {
    @include regularText(1rem, 1.5rem);
  }
  &.paragraph-small {
    @include regularText(0.875rem, 1.25rem);
    margin: 0 0 var(--mu-spacing-lg);
    &.same-line-height {
      line-height: 0.875rem;
    }
  }
  &.no-margin {
    margin: 0;
  }
  &.primary {
    color: var(--ion-color-primary);
  }
  &.secondary {
    color: var(--ion-color-secondary);
  }
}
