/* Paddings */
.mu-p-none {
  padding: 0;
}
.mu-p0 {
  padding: var(--mu-spacing-xxs);
}
.mu-p1 {
  padding: var(--mu-spacing-xs);
}
.mu-p2 {
  padding: var(--mu-spacing-sm);
}
.mu-p3 {
  padding: var(--mu-spacing-md);
}
.mu-p4 {
  padding: var(--mu-spacing-lg);
}
.mu-p5 {
  padding: var(--mu-spacing-xl);
}

.mu-pt-none {
  padding-top: 0;
}
.mu-pt0 {
  padding-top: var(--mu-spacing-xxs);
}
.mu-pt1 {
  padding-top: var(--mu-spacing-xs);
}
.mu-pt2 {
  padding-top: var(--mu-spacing-sm);
}
.mu-pt3 {
  padding-top: var(--mu-spacing-md);
}
.mu-pt4 {
  padding-top: var(--mu-spacing-lg);
}
.mu-pt5 {
  padding-top: var(--mu-spacing-xl);
}

.mu-pb-none {
  padding-bottom: 0;
}
.mu-pb0 {
  padding-bottom: var(--mu-spacing-xxs);
}
.mu-pb1 {
  padding-bottom: var(--mu-spacing-xs);
}
.mu-pb2 {
  padding-bottom: var(--mu-spacing-sm);
}
.mu-pb3 {
  padding-bottom: var(--mu-spacing-md);
}
.mu-pb4 {
  padding-bottom: var(--mu-spacing-lg);
}
.mu-pt5 {
  padding-bottom: var(--mu-spacing-xl);
}

.mu-py-none {
  padding-top: 0;
  padding-bottom: 0;
}
.mu-py0 {
  padding-top: var(--mu-spacing-xxs);
  padding-bottom: var(--mu-spacing-xxs);
}
.mu-py1 {
  padding-top: var(--mu-spacing-xs);
  padding-bottom: var(--mu-spacing-xs);
}
.mu-py2 {
  padding-top: var(--mu-spacing-sm);
  padding-bottom: var(--mu-spacing-sm);
}
.mu-py3 {
  padding-top: var(--mu-spacing-md);
  padding-bottom: var(--mu-spacing-md);
}
.mu-py4 {
  padding-top: var(--mu-spacing-lg);
  padding-bottom: var(--mu-spacing-lg);
}
.mu-py5 {
  padding-top: var(--mu-spacing-xl);
  padding-bottom: var(--mu-spacing-xl);
}

.mu-pl-none {
  padding-left: 0;
}
.mu-pl0 {
  padding-left: var(--mu-spacing-xxs);
}
.mu-pl1 {
  padding-left: var(--mu-spacing-xs);
}
.mu-pl2 {
  padding-left: var(--mu-spacing-sm);
}
.mu-pl3 {
  padding-left: var(--mu-spacing-md);
}
.mu-pl4 {
  padding-left: var(--mu-spacing-lg);
}
.mu-pl5 {
  padding-left: var(--mu-spacing-xl);
}

.mu-pr-none {
  padding-right: 0;
}
.mu-pr0 {
  padding-right: var(--mu-spacing-xxs);
}
.mu-pr1 {
  padding-right: var(--mu-spacing-xs);
}
.mu-pr2 {
  padding-right: var(--mu-spacing-sm);
}
.mu-pr3 {
  padding-right: var(--mu-spacing-md);
}
.mu-pr4 {
  padding-right: var(--mu-spacing-lg);
}
.mu-pr5 {
  padding-right: var(--mu-spacing-xl);
}

.mu-px-none {
  padding-right: 0;
  padding-left: 0;
}
.mu-px0 {
  padding-right: var(--mu-spacing-xxs);
}
.mu-px1 {
  padding-left: var(--mu-spacing-xs);
  padding-right: var(--mu-spacing-xs);
}
.mu-px2 {
  padding-left: var(--mu-spacing-sm);
  padding-right: var(--mu-spacing-sm);
}
.mu-px3 {
  padding-left: var(--mu-spacing-md);
  padding-right: var(--mu-spacing-md);
}
.mu-px4 {
  padding-left: var(--mu-spacing-lg);
  padding-right: var(--mu-spacing-lg);
}
.mu-px5 {
  padding-left: var(--mu-spacing-xl);
  padding-right: var(--mu-spacing-xl);
}

/* Margins */
.mu-m-none {
  margin: 0;
}
.mu-m0 {
  margin: var(--mu-spacing-xxs);
}
.mu-m1 {
  margin: var(--mu-spacing-xs);
}
.mu-m2 {
  margin: var(--mu-spacing-sm);
}
.mu-m3 {
  margin: var(--mu-spacing-md);
}
.mu-m4 {
  margin: var(--mu-spacing-lg);
}
.mu-m5 {
  margin: var(--mu-spacing-xl);
}

.mu-mt-none {
  margin-top: 0;
}
.mu-mt0 {
  margin-top: var(--mu-spacing-xxs);
}
.mu-mt1 {
  margin-top: var(--mu-spacing-xs);
}
.mu-mt2 {
  margin-top: var(--mu-spacing-sm);
}
.mu-mt3 {
  margin-top: var(--mu-spacing-md);
}
.mu-mt4 {
  margin-top: var(--mu-spacing-lg);
}
.mu-mt5 {
  margin-top: var(--mu-spacing-xl);
}

.mu-mb-none {
  margin-bottom: 0;
}
.mu-mb0 {
  margin-bottom: var(--mu-spacing-xxs);
}
.mu-mb1 {
  margin-bottom: var(--mu-spacing-xs);
}
.mu-mb2 {
  margin-bottom: var(--mu-spacing-sm);
}
.mu-mb3 {
  margin-bottom: var(--mu-spacing-md);
}
.mu-mb4 {
  margin-bottom: var(--mu-spacing-lg);
}
.mu-mt5 {
  margin-bottom: var(--mu-spacing-xl);
}

.mu-my-none {
  margin-top: 0;
  margin-bottom: 0;
}
.mu-my0 {
  margin-top: var(--mu-spacing-xxs);
  margin-bottom: var(--mu-spacing-xxs);
}
.mu-my1 {
  margin-top: var(--mu-spacing-xs);
  margin-bottom: var(--mu-spacing-xs);
}
.mu-my2 {
  margin-top: var(--mu-spacing-sm);
  margin-bottom: var(--mu-spacing-sm);
}
.mu-my3 {
  margin-top: var(--mu-spacing-md);
  margin-bottom: var(--mu-spacing-md);
}
.mu-my4 {
  margin-top: var(--mu-spacing-lg);
  margin-bottom: var(--mu-spacing-lg);
}
.mu-my5 {
  margin-top: var(--mu-spacing-xl);
  margin-bottom: var(--mu-spacing-xl);
}

.mu-ml-none {
  margin-left: 0;
}
.mu-ml0 {
  margin-left: var(--mu-spacing-xxs);
}
.mu-ml1 {
  margin-left: var(--mu-spacing-xs);
}
.mu-ml2 {
  margin-left: var(--mu-spacing-sm);
}
.mu-ml3 {
  margin-left: var(--mu-spacing-md);
}
.mu-ml4 {
  margin-left: var(--mu-spacing-lg);
}
.mu-ml5 {
  margin-left: var(--mu-spacing-xl);
}

.mu-mr-none {
  margin-right: 0;
}
.mu-mr0 {
  margin-right: var(--mu-spacing-xxs);
}
.mu-mr1 {
  margin-right: var(--mu-spacing-xs);
}
.mu-mr2 {
  margin-right: var(--mu-spacing-sm);
}
.mu-mr3 {
  margin-right: var(--mu-spacing-md);
}
.mu-mr4 {
  margin-right: var(--mu-spacing-lg);
}
.mu-mr5 {
  margin-right: var(--mu-spacing-xl);
}

.mu-mx-none {
  margin-left: 0;
  margin-right: 0;
}
.mu-mx0 {
  margin-right: var(--mu-spacing-xxs);
  margin-left: var(--mu-spacing-xxs);
}
.mu-mx1 {
  margin-left: var(--mu-spacing-xs);
  margin-right: var(--mu-spacing-xs);
}
.mu-mx2 {
  margin-left: var(--mu-spacing-sm);
  margin-right: var(--mu-spacing-sm);
}
.mu-mx3 {
  margin-left: var(--mu-spacing-md);
  margin-right: var(--mu-spacing-md);
}
.mu-mx4 {
  margin-left: var(--mu-spacing-lg);
  margin-right: var(--mu-spacing-lg);
}
.mu-mx5 {
  margin-left: var(--mu-spacing-xl);
  margin-right: var(--mu-spacing-xl);
}

.mu-h100 {
  height: 100%;
}
.mu-h100vh {
  height: 100vh;
}
.mu-w100 {
  height: 100%;
}
.mu-mh100 {
  min-height: 100%;
}

.mu-mh100vh {
  min-height: 100%;
}
.mu-w100vw {
  width: 100vw;
}

.mu-border-radius {
  border-radius: var(--mu-border-radius);
}
