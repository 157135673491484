.mu-flex {
  display: flex;
  width: 100%;
  &-inline {
    display: inline-flex;
    width: auto;
  }
  &-auto {
    width: auto;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }
  .mu-flex-grow {
    flex-grow: 1;
  }
  .mu-flex-shrink {
    flex-shrink: 1;
  }
}
.mu-grid {
  display: grid;
  gap: 0.5rem;
  &-inline {
    display: inline-grid;
    gap: 0.5rem;
  }
}

.row-span-2 {
  grid-row: span 2;
}
.row-span-3 {
  grid-row: span 3;
}
.row-span-4 {
  grid-row: span 4;
}
.row-span-5 {
  grid-row: span 5;
}
.column-span-2 {
  grid-column: span 2;
}
.column-span-3 {
  grid-column: span 3;
}
.column-span-4 {
  grid-column: span 4;
}
.column-span-5 {
  grid-column: span 5;
}
