.object-fit-cover {
  object-fit: cover;
}

.native-section-heading {
  background: var(--ion-color-primary);
  position: sticky;
  top: 0;
  z-index: 10;
}

.visually-hidden {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
