.drupal-content {
  p {
    padding-bottom: var(--mu-spacing-sm);
    font-size: 1rem;
    line-height: 1.5rem;
  }
  strong,
  b {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    padding-bottom: var(--mu-spacing-sm);
  }
  ol,
  ul {
    li {
      position: relative;
      font-size: 1rem;
      line-height: 1.5;
      padding-left: 1.25em;
    }
    ol,
    ul {
      padding-left: 0.5em;
    }
  }

  ol {
    li {
      margin-left: 1rem;
    }
  }
  ul {
    li {
      &:before {
        content: '';
        width: 0.875em;
        height: 0.875em;
        background-color: currentColor;
        -webkit-mask: url('../assets/dot-circle.svg') no-repeat center;
        mask: url('../assets/dot-circle.svg') no-repeat center;
        position: absolute;
        left: 0;
        top: 0.25em;
      }
      ul {
        li {
          &:before {
            content: '';
            height: 0.3em;
            background-color: currentColor;
            -webkit-mask: url('../assets/circle-solid.svg') no-repeat center;
            mask: url('../assets/circle-solid.svg') no-repeat center;
            position: absolute;
            left: 0;
            top: 0.5em;
          }
        }
      }
    }
  }

  img {
    max-width: 100%;
    margin: 0;
    height: auto;
  }

  details:focus-within {
    outline: 2px solid var(--ion-color-primary);
  }

  details {
    margin: 0.5rem 0 1rem;
    @include desktop {
      summary::-webkit-details-marker {
        display: none;
      }
    }

    summary {
      outline: none;
      color: var(--ion-color-tertiary);
      line-height: 1.5em;
      list-style: none;
      cursor: pointer;
      position: relative;
      padding: 1rem 0;
      @include desktop {
        order: 1;
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
      &::before {
        content: '';
        width: 1em;
        height: 1em;
        background-color: currentColor;
        -webkit-mask: url('../assets/chevron-down.svg') no-repeat center;
        mask: url('../assets/chevron-down.svg') no-repeat center;
        transform: rotate(-90deg);
        transition: all 0.2s ease-in-out;
        color: var(--ion-color-primary);
        top: 0.175em;
        position: relative;
        margin-right: 0.3rem;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: var(--mu-color-border);
        order: 3;
      }
    }
    .details-wrapper {
      padding-left: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--mu-color-border);
    }
    &[open] {
      summary {
        transition: all 1s ease-in-out;
        &::before {
          transition: all 0.2s ease-in-out;
          transform: rotate(0deg);
          margin-right: 0.3rem;
        }
        &::after {
          transition: all 0.2s ease-in-out;
          height: 0px;
        }
      }
    }
  }
  a[href] {
    color: var(--ion-color-tertiary);
    &:not([data-entity-substitution]):not([href^='mailto:']):not([href^='tel:']) {
      &::after {
        content: '';
        width: 0.7em;
        height: 0.7em;
        background-color: currentColor;
        -webkit-mask: url('../assets/external-link-alt.svg') no-repeat center;
        mask: url('../assets/external-link-alt.svg') no-repeat center;
        color: var(--ion-color-tertiary);
        margin-left: 0.25em;
        display: inline-block;
      }
    }
    &[data-entity-substitution='media'] {
      &::after {
        content: '';
        width: 0.7em;
        height: 0.7em;
        background-color: currentColor;
        -webkit-mask: url('../assets/download.svg') no-repeat center;
        mask: url('../assets/download.svg') no-repeat center;
        color: var(--ion-color-tertiary);
        margin-left: 0.25em;
        display: inline-block;
      }
    }
    &[href^='tel:'] {
      &::before {
        content: '';
        width: 0.7em;
        height: 0.7em;
        background-color: currentColor;
        -webkit-mask: url('../assets/phone-alt-solid.svg') no-repeat center;
        mask: url('../assets/phone-alt-solid.svg') no-repeat center;
        color: var(--ion-color-tertiary);
        margin-right: 0.25em;
        display: inline-block;
      }
    }
  }

  .field--name-field-media-oembed-video {
    .field__item {
      overflow: hidden;
      /* 16:9 aspect ratio */
      padding-top: 56.25%;
      position: relative;
      & > iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
  // make sure a svg image with the same name exists in ../assets
  @each $icon in facebook, twitter, linkedin, instagram, youtube, doc, docx, xls,
    xlsx, pdf, mail
  {
    .link-#{$icon} {
      &::before {
        content: '';
        width: 1em;
        height: 1em;
        margin-right: 0.25em;
        display: inline-block;
        vertical-align: middle;
        background-color: currentColor;
        -webkit-mask: url('../assets/#{$icon}.svg') no-repeat center;
        mask: url('../assets/#{$icon}.svg') no-repeat center;
        color: var(--color-#{$icon});
      }
      &::after {
        content: none !important;
      }
    }
  }
  i {
    font-style: italic;
  }
}
