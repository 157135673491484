.mu-bg1 {
  background: var(--ion-color-primary);
  &-gradient {
    background: var(--ion-color-primary-gradient);
  }
}

.mu-bg2 {
  background: var(--ion-color-secondary);
}

.mu-bg3 {
  background: var(--ion-color-tertiary);
}

.mu-bg {
  &-danger {
    background: var(--ion-color-danger);
  }
  &-success {
    background: var(--ion-color-success);
  }
  &-warning {
    background: var(--ion-color-warning);
  }
  &-info,
  &-medium {
    background: var(--ion-color-medium);
  }
  &-light {
    background: var(--ion-color-light);
    &-shade {
      background: var(--ion-color-light-shade);
    }
  }
  &-color {
    background: var(--mu-color-background);
  }
}

.mu-b {
  &-light {
    border-color: var(--ion-color-light);
  }
  &-medium {
    border-color: var(--ion-color-medium);
  }
  &-color {
    border-color: var(--mu-color-border);
  }
  &-color-dark {
    border-color: var(--mu-color-border-dark);
  }
}

.mu-color1 {
  color: var(--ion-color-primary);
}

.mu-color2 {
  color: var(--ion-color-secondary);
}

.mu-color3 {
  color: var(--ion-color-tertiary);
}
