.mu-input {
  &-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: minmax(0, 1fr) auto;
    position: relative;
    width: 100%;
    margin-bottom: var(--mu-spacing-md);
  }
  &-field {
    width: 100%;
    height: calc(var(--mu-button-text-height) + 2 * var(--mu-spacing-xxs));
    border-top: var(--mu-border-width) solid var(--mu-color-border);
    border-bottom: var(--mu-border-width) solid var(--mu-color-border);
    border-left: var(--mu-border-width) solid var(--mu-color-border);
    border-radius: var(--mu-spacing-xxs);
    padding: 0 var(--mu-spacing-xs);
    overflow: hidden;
    white-space: nowrap;
  }
}

button {
  font-family: 'TheSans B6 SemiBold', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  text-transform: none;

  /* button-text-height is 40px and ion-button is not responsive to height */
  height: calc(var(--mu-button-text-height) + 2 * var(--mu-spacing-xs));
  margin: 0;
  --padding-start: var(--mu-spacing-md);
  --padding-end: var(--mu-spacing-md);
  --box-shadow: none;
}
