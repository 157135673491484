:root {
  --ion-backdrop-color: transparent;
  --ion-backdrop-opacity: 1;
  --ion-overlay-background-color: var(--mu-color-background);
  --ion-border-color: var(--mu-color-border);
  --ion-box-shadow-color: var(--mu-color-border);
  --ion-tab-bar-background: var(--ion-color-primary-contrast);
  --ion-tab-bar-background-focused: var(--mu-color-background);
  --ion-tab-bar-border-color: var(--ion-border-color);
  // --ion-tab-bar-color:
  // --ion-tab-bar-color-selected:
  // --ion-toolbar-background:
  // --ion-toolbar-border-color:
  // --ion-toolbar-color:	Color of the components in the Toolbar
  // --ion-toolbar-segment-color:	Color of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-color-checked:	Color of the checked Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background:	Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-background-checked:	Background of the Segment Buttons in the Toolbar
  // --ion-toolbar-segment-indicator-color:	Color of the Segment Button indicator in the Toolbar
  // --ion-item-background:	Background of the Item
  // --ion-item-border-color:	Border color of the Item
  // --ion-item-color:	Color of the components in the Item
  // --ion-placeholder-color:	Color of the placeholder in Inputs
}

fa-icon {
  font-size: 1.125rem;
}

ion-img {
  height: 100%;
}

ion-popover {
  &.top-menu-popover {
    cursor: none;
    --backdrop-opacity: 0;
    --box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.15);
  }
  .popover-content {
    margin-top: var(--mu-spacing-lg);
    border: var(--mu-color-border);
    @include desktop {
      --min-width: 200px;
      --max-width: 600px;
    }
  }
  &.mu-popover-w-100 {
    --width: 100%;
  }

  ion-list {
    &.mu-popover-list {
      padding-top: var(--mu-spacing-sm);
      padding-bottom: var(--mu-spacing-sm);
      padding-left: var(--mu-spacing-xs);
      padding-right: var(--mu-spacing-xs);
    }
  }
}

ion-alert {
  @include desktop {
    &.select-alert {
      --min-width: 400px;
      --max-width: 600px;
    }
  }
}

ion-backdrop {
  background: rgba(0, 0, 0, 0.15);
  cursor: default;
}

.native-menu {
  .action-sheet-group {
    background: var(--ion-color-secondary);
  }
}

ion-modal {
  &.message-modal {
    --width: 85vw;
    --height: 85vh;
  }

  &.photo-uploader-modal {
    --width: 40vw;
    --height: 85vh;
  }

  @include tablet {
    &.message-modal {
      --width: 100vw;
      --height: 100vh;
    }
    &.photo-uploader-modal {
      --width: 100%;
      --height: 100%;
    }
  }
}

.application-selector {
  ion-backdrop {
    background: rgba(0, 0, 0, 0);
  }
  .popover-content {
    border-radius: 3px;
    --width: 100%;
    --max-width: 1000;
  }
  .sc-ion-popover-ios {
    margin-left: 10px;
    opacity: 1;
  }
  .popover-viewport {
    overflow-x: hidden;
    max-height: 200px;
    overflow-y: auto;
  }
  .sc-ion-popover-xs {
    margin-left: 10px;
  }

  .sc-ion-popover-md {
    left: 40px !important;
  }

  @include tablet {
    .sc-ion-popover-md {
      left: 18px !important;
    }
  }

  .popover-wrapper .popover-content {
    opacity: 1;
    position: absolute !important;
  }
  .popover-arrow.sc-ion-popover-ios {
    display: none;
  }
}

.application-selector::part(content){
  width: 100%;
  margin-left: 0px;
  position:absolute;
}

@include tablet {
  .application-selector::part(content){
    left: 16px;
    top: 230px;
  }
}

@include desktop {
  .application-selector::part(content){
    left: 40px;
    top: 254px;
  }
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.confirm-alert {
  @include tablet {
    --min-width: 300px;
  }
  @include desktop {
    --min-width: 450px;
  }
  .alert-title {
    color: var(--ion-color-tertiary);
  }
  .alert-message {
    color: var(--ion-color-primary);
  }

  .alert-button-group {
    justify-content: space-around;
    flex-wrap: nowrap;
    .alert-button {
      padding-left: 1em;
      padding-right: 1em;
      font-size: 14px;
      font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
      text-transform: none;
      width: 50%;
      .alert-button-inner {
        justify-content: space-around;
      }

      &:last-child {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-light);
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        padding-left: 1em;
        padding-right: 1em;
        font-size: 14px;
        font-family: 'TheSans Regular', Arial, Helvetica, sans-serif;
        text-transform: none;
      }
      &:first-child {
        color: var(--ion-color-tertiary);
        text-decoration: underline;
      }
    }
  }
}

#mu-status-container {
  fa-icon {
    .svg-inline--fa {
      width: 15px;
      height: 15px;
    }
  }
}

em {
  font-style: italic;
}
strong {
  font-weight: 800;
}


.aria-invisible:not(:focus) {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px;  
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.aria-invisible:focus {
  border: 1px solid black
}

